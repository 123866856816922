<script setup>

import { KNOLIA } from "@/knolia"
import { ref, defineEmits, onMounted } from "vue"
import { Gems } from "@/gems"
import { MESSAGES } from "../messages"
import { useUserStore } from '@/store/modules/user.js'

const emits = defineEmits(['close-profile-modal'])
const userData = ref({})
const coreData = ref({})
const selTeams = ref(null)
const photoUser = ref(null)
const inputUserPhoto = ref(null)
const defaultImage = ref(require('@/images/default.png'))

const store = useUserStore()

function DisassociateTeam(team) {
    userData.value.assigned_teams.forEach((assignedTeam, i) => {
        if (assignedTeam.id == team.id) {
            userData.value.assigned_teams.splice(i, 1)
        }
    })
}

function AssociateTeam() {
    let selectedTeam, hasDuplicated = false

    coreData.value.teams.forEach(team => {
        if (team.id == selTeams.value.value)
            selectedTeam = team
    })

    userData.value.assigned_teams.forEach((team, i) => {
        if (team.id == selectedTeam.id)
            hasDuplicated = true

        if (!team.id)
            userData.value.assigned_teams.splice(i, 1)
    })

    if (!hasDuplicated)
        userData.value.assigned_teams.push(selectedTeam)
}

function SelectUserPhoto() {
    const reader = new FileReader()

    reader.onload = () => userData.value.src = reader.result
    reader.readAsDataURL(inputUserPhoto.value.files[0] ?? new Blob())
}

async function EditUser() {
    const formData = new FormData()
    const currentPass = document.getElementById('txt-actual-password').value
    const newPass = document.getElementById('txt-new-password').value
    const confirmPass = document.getElementById('txt-confirm-password').value

    if (currentPass && newPass && confirmPass) {
        const passRules = Gems.GetPassRulesConfirmation(newPass, confirmPass, userData.value.username)

        if (!passRules.hasAtLeast12Chars) {
            Gems.Telegram('Password precisa de 12 caracteres', 'tele-not-allowed')
            return
        }

        if (!passRules.hasUpperAndLowerChars) {
            Gems.Telegram('Password precisa de ao menos um caractere maiúsculo e minúsculo', 'tele-not-allowed')
            return
        }

        if (!passRules.hasNumbers) {
            Gems.Telegram('Password precisa de ao menos um número', 'tele-not-allowed')
            return
        }

        if (!passRules.hasSymbols) {
            Gems.Telegram('Password precisa de ao menos um símbolo', 'tele-not-allowed')
            return
        }

        if (passRules.hasUsername) {
            Gems.Telegram('Password não pode conter o username', 'tele-not-allowed')
            return
        }

        if (!passRules.isPassAndConfirmEquals) {
            Gems.Telegram('Passwords não coincidem', 'tele-not-allowed')
            return
        }
    }

    if (inputUserPhoto.value.files[0])
        formData.append('photo', inputUserPhoto.value.files[0])

    const bodyData = Object.assign(userData.value, {
        currentPass,
        newPass,
        confirmPass
    })

    formData.append('body', JSON.stringify(bodyData))

    const response = await KNOLIA.CallBackEnd('post', 'edit-user', formData)
    if (!response.data.error)
        Gems.Telegram(MESSAGES.SUCCESS.SAVED, 'tele-success')

    if(KNOLIA.GetRequestedUserId() == KNOLIA.GetLoggedUserId())
        await store.GetUser()
}

function HideShowPassword(id, iconId) {
    const x = document.getElementById(id);
    const icon = document.getElementById(iconId);
    if (x.type === "password") {
        x.type = "text";
        icon.className = "fas fa-eye-slash i-icon";
    } else {
        icon.className = "fas fa-eye i-icon";
        x.type = "password";
    }
}

onMounted(async () => {
    const response = await KNOLIA.CallBackEnd('get', `get-user`)
    userData.value = response.data.user
    coreData.value = response.data.core
    
    const newTeam = document.getElementById('add-team')
        
    if(newTeam)
        Gems.Tooltip(newTeam, 'Adicionar colaborador(a) a esta equipa', 'tooltip')
})
</script>

<template>
    <div id="div-container-modal" class="modal">
        <div id="div-user-content" class="modal-content">
            <div class="close-modal">
                <i class="fas fa-times" @click="emits('close-profile-modal')"></i>
            </div>

            <div id="div-user-data">
                <div class="div-user-input" id="div-user-img" @click="inputUserPhoto.click()">
                    <input ref="inputUserPhoto" class="file-user-photo" type="file" @change="SelectUserPhoto">
                    <img ref="photoUser" class="div-logo" :src="userData.src" @error="$event.target.src = defaultImage">
                </div>

                <div class="div-group">
                    <div class="div-user-input div-little" id="div-user-internal">
                        <label for="">Nº Interno</label>
                        <input type="text" v-model="userData.user_internal" :disabled="!KNOLIA.isAdmin()">
                    </div>
                    <div class="div-user-input div-larger" id="div-user-name">
                        <label for="">Nome Completo</label>
                        <input type="text" v-model="userData.complete_name" :disabled="!KNOLIA.isAdmin()">
                    </div>
                </div>


                <div class="div-group">
                    <div class="div-user-input div-little" id="div-user-internal">
                        <label for="">Género</label>
                        <select class="cursor-pointer" v-model="userData.gender_id">
                            <option :value="gender.id" v-for="gender in coreData.genders" :key="gender.id">
                                {{ gender.designation }}
                            </option>
                        </select>
                    </div>
                    <div class="div-user-input div-larger" id="div-user-name">
                        <label for="">Nome Profissional</label>
                        <input type="text" v-model="userData.professional_name">
                    </div>
                </div>

                <div class="div-group">
                    <div class="div-user-input flex-1" id="div-user-job">
                        <label for="">Função</label>

                        <select :class="{'cursor-pointer': KNOLIA.isAdmin()}" v-model="userData.job_id" :disabled="!KNOLIA.isAdmin()">
                            <option :value="job.id" v-for="job in coreData.jobs" :key="job.id">
                                {{ job.designation }}
                            </option>
                        </select>
                    </div>

                    <div class="div-user-input flex-1" id="div-user-category">
                        <label for="">Categoria</label>

                        <select :class="{'cursor-pointer': KNOLIA.isAdmin()}" v-model="userData.category_id" :disabled="!KNOLIA.isAdmin()">
                            <option :value="category.id" v-for="category in coreData.categories" :key="category.id">
                                {{ category.designation }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="div-group">
                    <div class="div-user-input flex-1" id="div-user-email">
                        <label for="">E-mail</label>
                        <input type="text" v-model="userData.user_email" :disabled="!KNOLIA.isAdmin()">
                    </div>
                    <div class="div-user-input flex-1" id="div-user-username">
                        <label for="">Username</label>
                        <input type="text" v-model="userData.username" :disabled="!KNOLIA.isAdmin()">
                    </div>
                </div>

                <div class="div-group">
                    <div class="left flex-1">
                        <div class="div-user-input" id="div-user-teams">
                            <label for="">Equipas</label>
    
                            <div id="div-teams" v-if="KNOLIA.isAdmin()">
    
                                <select ref="selTeams" class="ddl-teams cursor-pointer">
                                    <option :value="team.id" v-for="team in coreData.teams" :key="team.id">
                                        {{ team.designation }}
                                    </option>
                                </select>
    
                                <i id="add-team" class="fas fa-plus cursor-pointer" @click="AssociateTeam()"></i>
                            </div>
    
                            <div id="div-assigned-teams">
                                <div class="div-assigned-team" v-for="team in userData.assigned_teams" :key="team.id">
                                    {{ team.designation }}
                                    <i v-show="team.id" class="fas fa-minus close" @click="DisassociateTeam(team)"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="right flex-1">
                        <div class="group div-user-input" id="div-user-password">
                            <label for="">Password atual</label>
                            <div class="div-eye-container">
                                <input type="password" id="txt-actual-password">
                                <i class="fas fa-eye i-icon" id="icon-actual-password" @click="HideShowPassword('txt-actual-password', 'icon-actual-password')"></i>
                            </div>
                        </div>
                        <div class="div-user-input" id="div-user-new-password">
                            <label for="">Nova Password</label>
                            <div class="div-eye-container">
                                <input type="password" id="txt-new-password">
                                <i class="fas fa-eye i-icon" id="icon-new-password" @click="HideShowPassword('txt-new-password', 'icon-new-password')"></i>
                            </div>
                        </div>
                        <div class="div-user-input" id="div-user-confirm-password">
                            <label for="">Confirmar Nova Password</label>
                            <div class="div-eye-container">
                                <input type="password" id="txt-confirm-password">
                                <i class="fas fa-eye i-icon" id="icon-confirm-password" @click="HideShowPassword('txt-confirm-password', 'icon-confirm-password')"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="div-save-user-data" class="div-group">
                    <div class="flex-1 div-toggle-container" v-if="KNOLIA.isAdmin()">
                        <div class="div-toggle">
                            <label for="">Ativo</label>
                            <i @click="userData.active = !userData.active" class="fas cursor-pointer"
                            :class="{ 'fa-toggle-on': userData.active, 'fa-toggle-off': !userData.active }"></i>
                        </div>
                        <div class="div-toggle">
                            <label for="">Administrador</label>
                            <i @click="userData.admin = !userData.admin" class="fas cursor-pointer"
                            :class="{ 'fa-toggle-on': userData.admin, 'fa-toggle-off': !userData.admin }"></i>
                        </div>
                    </div>
                    <div class="flex-1 div-save">
                        <button class="logo-btn" @click="EditUser()">GRAVAR</button>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<style scoped>

.modal-content {
    position: absolute;
    top: 2%;
    left: 25%;
    transform: translate(0, 0);
}

#div-save-user-data {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 1.2em;
}

#div-user-img {
    margin: 0 auto;
    cursor: pointer;
    width: 150px;
}

.div-logo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}

.div-group {
    padding: 10px;
    display: flex;
    gap: 10px;
}

.div-user-input>* {
    width: -webkit-fill-available;
}

label {
    font-weight: bold;
    margin-right: 5px;
    display: block;
}


.div-assigned-team {
    border-radius: 5px;
    border: 1px solid;
    padding: 5px;
    margin: 5px 5px 5px 0px;
}

#div-assigned-teams {
    display: flex;
    margin-top: 5px;
    flex-wrap: wrap;
}

#div-teams>.fa-plus {
    margin-left: 5px;
}

.file-user-photo {
    cursor: pointer;
    display: none;
}

.div-toggle {
    text-align: center;
}

.div-little {
    width: 15%
}

.div-larger {
    width: 85%;
}

.flex-1 {
    flex: 1;
}

.flex-5 {
    flex: 5;
}

.right div:not(:first-child) {
    padding: 10px 0;
}

.ddl-teams {
    width: 95%;
}

.div-toggle-container {
    display: none !important;
    gap: 10px;
    justify-content: center;
}

.div-save {
    text-align: end;
}

.close-modal {
    display: flex;
    justify-content: flex-end;
    padding: 10px 10px 0px 0px;
}

.close-modal i {
    font-size: 1.5em;
    cursor: pointer;
}

.modal-content {
    border: 1px solid;
}

.div-eye-container {
    position: relative;
    padding: 0 !important;
}

.div-eye-container input {
    width: -webkit-fill-available;
}

.i-icon {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 30%;
    font-size: 16px;
}

.div-eye-container input::-ms-reveal {
    display: none;
}
</style>
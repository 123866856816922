<script setup>
import { onMounted, onUpdated, defineEmits } from "vue"
import { ref } from "@vue/reactivity"
import { KNOLIA } from "@/knolia"
import { Gems } from "@/gems"
import router from "../router"
import { MESSAGES } from "../messages"
import { useUserStore } from '@/store/modules/user.js'

const store = useUserStore()
const emits = defineEmits('control-modal')

const validateSkills = ref(false)
const validationList = ref(false)

async function GetValidationList() {
  KNOLIA.CallBackEnd("post", "get-validation-list")
    .then((res) => {
      validationList.value = res.data
    });

    store.SetCountValidations(0)
}

async function Validate(elem) {
  Gems.Telegram("Publicar decisão? <br> <br> (Pressionar [OK] irá concluir o processo de validação)", "tele-info", "tele-ok", "tele-cancel")
    .then(
      () => {
        KNOLIA.CallBackEnd("post", "control-rating-skills", { elem, type: 'validate' })
          .then((res) => {
            if(res.error) {
              Gems.Telegram(MESSAGES.ERROR.DEFAULT, 'tele-not-allowed')
              return
            }
            GetValidationList();
          });
      },
      () => {}
    )
}

function DefineEyeIconStatus(elem) {
  let cssClass = 'fas'
  
  if(!KNOLIA.ToBoolean(elem.has_files) || !KNOLIA.ToBoolean(elem.has_comments))
    cssClass = 'far'

  if(KNOLIA.ToBoolean(elem.has_unread_messages))
    cssClass += ' new-message-animate'

  return cssClass
}

onMounted(() => {
    validateSkills.value = router.currentRoute.value.params.validateSkills
    GetValidationList()
});

onUpdated(() => {
  validateSkills.value = router.currentRoute.value.params.validateSkills

  validationList.value.forEach((elem, i) => {
    const docIcon = document.getElementById(`doc-btn-${i}`)
    const chatIcon = document.getElementById(`chat-btn-${i}`)
    const validationChoose = document.getElementById(`validation-choose-${i}`)
    const showModal = document.getElementById(`show-modal${i}`)

    if(docIcon)
      Gems.Tooltip(docIcon, 'Documentos', 'tooltip')
    
    if(chatIcon)
      Gems.Tooltip(chatIcon, 'Mensagens', 'tooltip')
    
    if(validationChoose)
      Gems.Tooltip(validationChoose, 'Confiança do validador', 'tooltip')

    if(showModal)
      Gems.Tooltip(showModal, 'Detalhes', 'tooltip')
  })
});
</script>

<template>
  <div class="div-validate-list scrollbar">
    
    <div v-if="validationList.length == 0" class="div-empty-list">
      Sem validações disponiveis
    </div>

    <div v-else-if="validationList.length > 0">
      <div class="list-item list-header">
        <span>Data pedido</span>
        <span class="span-claimant">Requerente</span>
        <span class="span-elem">Descrição</span>
        <span>Validador</span>
        <span>Validação</span>
        <span>Data validação</span>
        <span></span>
      </div>

      <div class="list-item" :class="{'bg-alt-line': i % 2 == 0}" v-for="(elem, i) in validationList" :key="i">
        
        <span>{{ elem.request_date }}</span>
        
        <span class="span-claimant">{{ elem.user_name }}</span>

        <span class="span-elem line-clamp line-clamp-1" @mouseenter="Gems.TooltipIfNeeded($event.target)">{{ elem.designation }}</span>

        <span>{{ elem.validator_name }}</span>

        <span>
            <div v-if="elem.validation == 0">Invalidado</div>
            <div v-if="elem.validation == 1">Validado</div>
            <div v-if="elem.validation == 2">--</div>
        </span>

        <span>{{ elem.validation_date }}</span>

        <div class="modal-icon">
          <i class="fa-eye" :class="DefineEyeIconStatus(elem)" :id="`show-modal${i}`" @click="emits('control-modal', {bool: true, elem, chat: false})"></i>
        </div>
        
        <div class="div-btn">
          <button class="ddl-alt-padding" v-if="elem.validation != null && elem.validation_date == null" @click="Validate(elem)">PUBLICAR</button>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>

.modal-icon {
  font-size: 1.1em;
  cursor: pointer;
  display: flex;
  justify-content: space-evenly;
  /* margin: 0px 4px; */
}

.disabled {
  opacity: .7;
  cursor: default;
}

.div-validate-list {
  width: 100%;
  margin: 0 auto;
}

.div-empty-list {
  margin: 25% auto;
  text-align: center;
  font-size: 3em;
}

.list-item select {
  width: 100%;
  font-size: .9em;
  background-color: transparent;
}

.div-btn button {
  width: 100% !important;
  height: 100% !important;
}

.list-item {
  display: grid;
  grid-template-columns: 10% 20% 20% 10% 10% 10% 9% 9%;
  text-align: center;
  align-items: center;
  gap: .2%;
  padding: 5px;
  height: 30px;
}

.list-header {
  font-weight: bold;
}

.span-claimant,
.span-elem {
  text-align: left;
  cursor: default;
}

.input-small-padding {
  padding: 7px 10px !important;
}
 
</style>

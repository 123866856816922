<script setup>
import { KNOLIA } from "@/knolia";
import { onMounted, ref, defineEmits, defineProps, watch, onUpdated } from "vue";
import Comments from "@/components/Comments";
import { Gems } from "@/gems";
import { MESSAGES } from "../messages";

let emits = defineEmits(["control-modal", "set-validation", "refresh-list", "change-tab"]);
let props = defineProps(["element", "validateSkills", "showChat"]);
let arrComments = ref([]);
let filesList = ref([]);
let validation = ref(null);

function Upload(files) {
  if(props.validateSkills)
    return;

  const formData = new FormData();

  for (let file in files) {
    formData.append(file, files[file]);
    formData.append('elem', JSON.stringify(props.element))
  }

  KNOLIA.CallBackEnd("post", "import-ranking-files", formData).then((res) => {
    if (res.data.error)
      Gems.Telegram(MESSAGES.ERROR[`${res.data.message}`], "tele-not-allowed")

    GetContent()
  });
}

async function GetContent() {
  await KNOLIA.CallBackEnd("get", "get-ranking-files", {
    params: {
      id: props.element["skill_elem_id"],
      skills_ratings_id: props.element["skills_ratings_id"],
      requested_user: props.element["user_id"],
      showChat: props.showChat
    },
  }).then((res) => {
    filesList.value = res.data[0];
    arrComments.value = res.data[1];
  });
}

async function RemoveFile(file) {
  await KNOLIA.CallBackEnd("post", "remove-file", {
    file: file,
  }).then(() => {
    GetContent();
  });
}

async function RenameFile(file) {
  file.rename = !file.rename
  
  if(file.rename == false)
  KNOLIA.CallBackEnd("post", "set-operations&get=rename-ranking-file", {file}).then((res) => {
    if (res.error)
      Gems.Telegram(MESSAGES.ERROR.DEFAULT, "tele-not-allowed")

    GetContent()
  });
}

async function DownloadFile(file) {
  await KNOLIA.CallBackEnd("get", "download-file", {
    responseType: "blob",
    params: { file_name: file.file_name },
  }).then((res) => {
    const fileRes = new Blob([res.request.response], {
      type: "application/octet-stream",
    });

    const url = window.URL.createObjectURL(fileRes);
    const a = document.createElement("a");
    a.className = "hidden";
    a.href = url;
    a.download = file.designation;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    Gems.Telegram(MESSAGES.INFO.BROWSER_WARNING, "tele-success")
    ControlFileStatus(file)
  });
}

async function ControlFileStatus(file) {
  file.downloaded = !file.downloaded

  await KNOLIA.CallBackEnd('post', `set-operations&get=change-file-read-status`, { file }).then(
    (res) => {
      if(res.error)
        Gems.Telegram(MESSAGES.ERROR.DEFAULT, 'tele-not-allowed')
  });

}

async function GoToMessages() {
  if(props.element.has_unread_messages) 
    await KNOLIA.CallBackEnd('post', `set-operations&get=comment-read`, { params : {
      skill_rating_id: props.element.skills_ratings_id
    } }).then(
      (res) => {
        if(res.error)
          Gems.Telegram(MESSAGES.ERROR.DEFAULT, 'tele-not-allowed')

         Object.assign(props.element, {has_unread_messages: 0})
    })

    emits('change-tab', true)
}

onMounted(async () => {
  GetContent()
  if(props.element.validation != null)
    validation.value = props.element.validation

});

onUpdated(() => {
  filesList.value.forEach(file => {
    const divName = document.getElementById(`file-name${file.id}`)
    const divRemove = document.getElementById(`file-remove${file.id}`)
    const divRename = document.getElementById(`file-rename${file.id}`)
    const divReadStatus = document.getElementById(`file-read-status${file.id}`)
    const closeModal = document.getElementById('close-modal')

    if(divName)
      Gems.Tooltip(divName, 'Download', 'tooltip')
    
    if(divRemove)
      Gems.Tooltip(divRemove, 'Remover', 'tooltip')
      
    if(divRename)
      Gems.Tooltip(divRename, 'Renomear', 'tooltip')

    if(divReadStatus)
      Gems.Tooltip(divReadStatus, 'Destacar', 'tooltip')

    if(closeModal)
      Gems.Tooltip(closeModal, 'Fechar', 'tooltip')
  })
})


watch(validation, () => {
  Object.assign(props.element, {validation: validation.value})
});

</script>

<template>
  <div class="modal">
    <div class="modal-content">
      <div class="modal-header">
        <div class="div-modal-title-container">
          <div>
            <h3 class="modal-title">{{ element.user_name }}</h3>
            <h3 class="modal-title" id="title">{{ element.designation }}</h3>
            <span v-if="validateSkills">Classificação: {{ element.rating }}</span>
          </div>
        </div>

        <div class="div-validation">
          <i class="fas fa-times close-modal cursor-pointer" id="close-modal" @click="emits('control-modal', {bool: false, elem: element})"></i>
          <select class="select-files-btn" :class="{'cursor-pointer' : element.validation_date == null}" v-if="validateSkills" 
            v-model="validation" @change="emits('set-validation', element)" :disabled="element.validation_date != null">
            <option :value="null">--</option>
            <option :value="0">Invalidado</option>
            <option :value="1">Validado</option>
            <option :value="2">Abster-me</option>
          </select>
        </div>
      </div>

      <div class="tabs">
        <div class="tab" :class="{'tab-active': !showChat}" @click="emits('change-tab', false)">Documentos</div>
        <div class="tab" :class="{'tab-active': showChat}" @click="GoToMessages()">Comentários</div>
      </div>

      <div class="tab-content" v-if="!showChat">
        <div class="import-div" v-if="!validateSkills && !showChat">
        <input class="hidden-input" id="input" type="file" @change="Upload($event.target.files)" multiple />
        <button class="select-files-btn" onclick="document.getElementById('input').click()">
          UPLOAD
        </button>
      </div>
        <div class="modal-body" @paste="Upload($event.clipboardData.files)">
          <div v-show="filesList.length == 0"> Nenhum ficheiro importado </div>

          <div v-for="file in filesList" :key="file" class="file">
            <div class="file-name-row">
              <i class="fa-flag cursor-pointer" :class="{'fas': !file.downloaded, 'far': file.downloaded} " :id="`file-read-status${file.id}`"
                @click="ControlFileStatus(file)" v-if="validateSkills"></i>
              <i class="fas fa-file"></i>
              <p v-if="typeof file == 'object' && !file.rename" class="file-name" :id="`file-name${file.id}`" @click="DownloadFile(file)">
                {{ file.designation }}
              </p>
              <input class="input-rename" type="text" v-model="file.designation" v-else-if="file.rename">
            </div>
            <p class="file-icons-row span-date-icons" v-if="typeof file == 'object' && file.user_id == KNOLIA.GetLoggedUserId()">
              <span> {{ file.upload_date }} </span>
              <span class="span-icons">
                <i class="fas fa-spell-check remove" :class="{'selected-icon': file.rename}" :id="`file-rename${file.id}`" @click="RenameFile(file)"></i>
                <i class="fas fa-trash remove" :id="`file-remove${file.id}`" @click="RemoveFile(file)"></i>
              </span>
            </p>
          </div>
        </div>
      </div>

      <div class="tab-content comment-area" v-if="showChat">
        <Comments
          :comments="arrComments"
          :element="props.element"
          @get-content="GetContent()"
        />
      </div>
    </div>
  </div>
</template>
<style scoped>

.modal-content {
  background-color: var(--default-modal-color) !important;
}

.close {
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover {
  cursor: pointer;
}

.div-modal-title-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.modal-title {
  text-align: start;
  margin: 0;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 0px;
  align-items: center;
}

.import-div {
  text-align: left;
  padding: 1%;
}

.div-validation {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  flex-direction: column;
}

.tab-content {
  background-color: #060714;
  min-height: 487px;
  margin: 0px 20px 10px 20px;
}

.select-files-btn {
  width: 150px;
  padding: 5px;
}

.modal-body {
  padding: 10px;
  text-align: center;
}

.file {
  display: flex;
  justify-content: space-between;
  height: 1lh;
  padding: 5px;
}

.file p {
  margin: 0;
}

.file p i {
  margin-right: 5px;
}

.file-name-row {
  display: flex;
  align-items: center;
  flex: 4;
}

.file-icons-row {
  flex: 1;
}

.input-rename {
  width: 100%;
}

.file-name {
  cursor: pointer;
  margin-left: 5px !important;
}

.hidden-input {
  display: none;
}

.remove {
  cursor: pointer;
}

.selected-icon {
  color: #70cad1;
}


.fa-flag {
  margin-right: 5px;
}

.span-date-icons {
  display: flex;
  justify-content: space-between;
}

.span-icons {
  gap: 5px;
}

.tabs {
  display: flex;
  text-align: center;
  align-items: center;
  padding: 0px 20px;
}

.tab {
  flex: 1;
  padding: .8%;
  cursor: pointer;
  border: none;
}

.tab-active {
  background-color: #060714;
}

.close-modal {
  font-size: 1.5em;
}
</style>
